// Testimonial slider scripting
document.addEventListener("DOMContentLoaded", function () {
    const testimonialSlides = Array.from(
        document.querySelectorAll(".m-testimonialSlide")
    );

    if (testimonialSlides.length > 0) {
        testimonialSlides.forEach((testimonialSlide) => {
            testimonialSlide.classList.remove("--preload");
        });
        jQuery(".o-testimonialSlider__slides").slick({
            autoplay: true,
            autoplaySpeed: 7000,
            dots: true,
            speed: 1000,
        });
    }
});
