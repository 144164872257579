// Handles the linking of article previews to the articles they represent
document.addEventListener("DOMContentLoaded", function () {
    const articlePreviews = Array.from(
        document.querySelectorAll(".m-articlePreview")
    );
    if (articlePreviews.length > 0) {
        articlePreviews.forEach((articlePreview) => {
            articlePreview.addEventListener("click", function () {
                const articleURL = articlePreview.dataset.href;
                window.location.href = articleURL;
            });
        });
    }
});
